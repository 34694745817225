<template>
  <div v-loading="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)" style="min-height: 100vh;">
    <div id="index-layout">
      <div>
        <div>
          <div class="i-page-header">
            <div @click="userInfoDialog = true" style="float: left;min-width: 64px;color: black;">
              <img src="@/assets/common/user.png" width="40" style="" alt="" />
              <div>{{$t('index.i_account')}}</div>
            </div>

            <div style="float: right;">
              <svg style="vertical-align: middle;" t="1655452993620" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2356" width="24" height="24">
                <path d="M783.53 240.47C641.08 98.02 413.8 91.41 263.09 220.03V167.1c0-17.67-14.33-32-32-32s-32 14.33-32 32v128c0 17.67 14.33 32 32 32h128c17.67 0 32-14.33 32-32s-14.33-32-32-32h-48.15c125.55-101.54 310.66-94.06 427.34 22.62 124.75 124.77 124.75 327.8 0 452.56-124.78 124.75-327.78 124.75-452.56 0C225.28 677.84 192 597.48 192 512c0-17.67-14.33-32-32-32s-32 14.33-32 32c0 102.58 39.94 199.02 112.47 271.53 74.86 74.86 173.19 112.3 271.53 112.3 98.33 0 196.67-37.44 271.53-112.3 149.7-149.72 149.7-393.34 0-543.06z" p-id="2357"></path>
                <path d="M512 288c-17.67 0-32 14.33-32 32v185.38c0 12.81 5 24.88 14.06 33.94l123.31 123.31c6.25 6.25 14.44 9.38 22.62 9.38s16.38-3.12 22.62-9.38c12.5-12.5 12.5-32.75 0-45.25L544 498.75V320c0-17.67-14.33-32-32-32z" p-id="2358"></path>
              </svg>
              <router-link to="/goldLog" style="text-decoration: none;color: black;vertical-align: middle;">{{$t('index.i_coinsLog')}}</router-link>
            </div>
          </div>
          <div style="overflow: hidden;margin-left: 4%;margin-right: 2%;margin-top: 3%;">
            <div @click="$router.push('/leaderboard')" style="float: left;min-width: 64px;color: black;">
              <img src="@/assets/images/index006.png" width="40" style="" alt="" />
              <div>{{$t('index.i_leaderboard')}}</div>
            </div>
          </div>

          <div style="overflow: hidden;margin-left: 4%;margin-right: 2%;margin-top: 3%;">
            <div style="float: left;min-width: 64px;">
              <div @click="goContact()" style="min-width: 64px;">
                <img src="@/assets/images/index009.png" width="40" style="" alt="" />
                <div>{{$t('index.i_contact')}}</div>
              </div>
            </div>

            <div @click="$router.push({name: 'inviteFriend', params: {inviteEventDrawer: true}})" style="float: right;position: absolute;top: 25%;right: 2%;">
              <img src="@/assets/images/index007.gif" width="80" style="" alt="" />
            </div>
          </div>

          <div style="position: absolute;top: 2.5%;left: 0;right: 0;z-index: -1;">
            <img src="@/assets/images/index004.gif" style="width: 220px;" alt="" />
          </div>

          <div style="margin-top: 4%;color: black;">{{$t('index.i_totalCoins')}}</div>

          <div style="margin-top: 6%;">
            <img src="@/assets/common/moneyIcon.png" style="width: 30px;" alt="" />
            <span style="font-size: 42px;color: black;font-weight: bold;margin-left: 4px;">{{$store.state.goldNum}}</span>
          </div>
          <router-link to="/moneySteps">
            <el-button type="success" style="width: 60%;height: 60px;font-size: 20px;background-color: rgba(255, 207, 5, 1);color: black;border-radius: 30px;margin-top: 6%;">{{$t('common.i_startEarning')}}</el-button>
          </router-link>

          <div style="padding-top: 5%;padding-bottom: 4%;margin-top: 14%;">
            <el-button style="padding: 0;border-radius: 14px;position: relative;border: none !important;" @click="drawer = true">
              <!-- <router-link to="/convert"> -->
                <img width="162" src="@/assets/images/index002.png" alt="" />
                <span style="position: absolute;box-sizing: border-box;left: 12px;top: 12px;color: black;">
                  <span style="font-size: 14px;font-weight: bold;float: left;">{{$t('index.i_convert')}}</span>
                  <br />
                  <span style="font-size: 12px;font-weight: 100;line-height: 24px;">{{$t('index.i_convertMsg')}}</span>
                </span>
              <!-- </router-link> -->
            </el-button>
            <el-drawer
              :title="$t('convert.i_tips')"
              :visible.sync="drawer"
              :direction="direction"
              :before-close="handleClose">
              <span>{{$t('index.i_please')}}</span>
              <a href="https://bs.yohooinc.com/cash/view/dayDayCash2/invite/D5Z1JTT1">
                <el-button type="success" style="width: 60%;height: 60px;font-size: 20px;background-color: rgba(255, 207, 5, 1);color: black;border-radius: 30px;margin-top: 6%;">{{$t('convert.i_ok')}}</el-button>
              </a>
            </el-drawer>

            <el-button style="padding: 0;border-radius: 14px;position: relative;border: none !important;">
              <router-link to="/inviteFriend">
                <img width="162" src="@/assets/images/index003.png" alt="" />
                <span style="position: absolute;box-sizing: border-box;left: 12px;top: 12px;color: black;">
                  <span style="font-size: 14px;font-weight: bold;float: left;">{{$t('index.i_inviteFriends')}}</span>
                  <br />
                  <span style="font-size: 12px;font-weight: 100;line-height: 24px;">{{$t('index.i_inviteFriendsMsg')}}</span>
                </span>
              </router-link>
            </el-button>

            <div style="margin-top: 6px;">
              <el-button style="padding: 0;border-radius: 14px;position: relative;border: none !important;">
                <router-link to="/task">
                  <img src="@/assets/images/index005.png" style="width: 334px;" alt="" />
                  <span style="position: absolute;box-sizing: border-box;left: 12px;top: 18px;color: black;">
                    <span style="font-size: 14px;font-weight: bold;float: left;">{{$t('task.i_task')}}</span>
                    <br />
                    <span style="font-size: 12px;font-weight: 100;line-height: 24px;">{{$t('task.i_taskMsg')}}</span>
                  </span>
                </router-link>
              </el-button>
            </div>
          </div>

          <el-dialog :title="$t('index.i_accountC')" :visible.sync="userInfoDialog" width="80%" center>
            <div style="width: 100%;text-align: center;background-color: rgba(255, 233, 193, 1);padding-top: 9%;padding-bottom: 8%;border-radius: 20px;">
              <img src="@/assets/common/user.png" style="width: 30%;margin-bottom: 5%;" alt="" />
              <table style="margin: auto;font-size: 18px;">
                <tr style="height: 35px;">
                  <td style="text-align: right;width: 76px;color: black;">WID：</td>
                  <td style="text-align: left;color: black;">{{user.id}}</td>
                </tr>
                <tr v-if="user.gameUid != null" style="height: 35px;">
                  <td style="text-align: right;width: 76px;color: black;">UID：</td>
                  <td style="text-align: left;color: black;">{{user.gameUid}}</td>
                </tr>
                <tr v-if="user.isActivation == true" style="height: 35px;">
                  <td style="text-align: right;width: 76px;color: black;">{{$t('index.i_inviteCode')}}：</td>
                  <td style="text-align: left;color: black;">
                    <span @click="copyVal($event.target.innerText)">{{user.inviteCode}}</span>
                    <span style="margin-left: 2%;" @click="copyVal($event.currentTarget.previousElementSibling.innerText)">
                      <img src="@/assets/common/copyIcon.png" style="width: 10px;margin-bottom: 10px;" />
                    </span>
                  </td>
                </tr>
                <tr style="height: 35px;">
                  <td style="text-align: right;width: 76px;color: black;">Email：</td>
                  <td style="text-align: left;color: black;">{{user.email}}</td>
                </tr>
              </table>
            </div>
          </el-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      userInfoDialog: false,
      user: {},
      drawer: false,
      direction: 'btt',
    };
  },
  methods: {
    copyVal(val) {
      // 创建一个 Input标签
      let oInput = document.createElement("input");
      oInput.value = val;
      document.body.appendChild(oInput);
      // 选择对象
      oInput.select();
      // 执行浏览器复制命令
      // 复制命令会将当前选中的内容复制到剪切板中
      // 如这里构建的 Input标签
      document.execCommand("Copy");
      this.$message({
        message: this.$t("common.i_copy"),
        type: "success",
        showClose: true,
        center: true
      });
      // 复制成功后再将构造的标签 移除
      oInput.remove();
    },
    getUserInfo() {
      this.isLoading = true;
      this.$axios({
        url: "/webAndGame/webUser/index",
        method: "get"
      }).then((result) => {
        this.isLoading = false;
        if (result.data.code == 1) {
          if (result.data.data.userInfo != null) {
            this.user = result.data.data.userInfo;
            this.$store.state.goldNum = result.data.data.userInfo.goldNum;
          } else {
            localStorage.removeItem("token");
          }
        } else {
          this.$message({
            showClose: true,
            message: result.data.message,
            type: "error",
            center: true
          });
        }
      });
    },
    goContact() {
      window.location = "https://chat.whatsapp.com/JznJyQPwuQu8Pjp0nMuqao";
    }
  },
  created() {
    this.getUserInfo();
  }
};
</script>

<style>
#index-layout {
  width: 100%;
  height: 100%;
  padding-bottom: 2%;
  background: url("@/assets/images/loginBackground.png") no-repeat;
  background-size: 100%;
}
#index-layout .i-page-header {
  padding-top: 4%;
  margin-left: 4%;
  margin-right: 4%;
  overflow: hidden;
}
#index-layout .el-dialog {
  border-radius: 20px !important;
}
#index-layout .el-dialog__header {
  border-radius: 20px 20px 0 0 !important;
}
#index-layout .el-dialog__body {
  border-radius: 0 0 20px 20px !important;
}
#index-layout .el-button {
  border: none;
  background: none;
}
</style>
