<template>
  <div id="forgot-password-layout" v-loading="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <el-page-header :title="$t('common.i_back')" :content="$t('forgotPassword.i_forgotPass')" @back="goBack" class="f-page-header"></el-page-header>
    <el-steps :active="active" align-center finish-status="success">
      <el-step :title="$t('forgotPassword.i_verify')"></el-step>
      <el-step :title="$t('forgotPassword.i_modify')"></el-step>
      <el-step :title="$t('forgotPassword.i_finish')"></el-step>
    </el-steps>

    <div class="forgot-password-form">
      <Vcode :show="isShow" @success="vCodeSuccess()" :sliderText="$t('vCode.i_sliderText')" :failText="$t('vCode.i_failText')" :successText="$t('vCode.i_successText')" @close="close"></Vcode>
      <div v-if="active == 0">
        <el-form ref="forgotPasswordForm" :model="forgotPasswordForm" :rules="forgotPasswordRules">
          <el-form-item :label="$t('register.i_enterYourEmail')" prop="email">
            <el-input type="mail" v-model="forgotPasswordForm.email" :placeholder="$t('register.i_enterYourEmail')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('register.i_getVerifyCode')" prop="verifyCode">
            <div style="overflow: hidden;width: 100%;">
              <div style="float: left;width: 54%;">
                <el-input type="number" v-model="forgotPasswordForm.verifyCode" :placeholder="$t('register.i_enterVerifyCode')"></el-input>
              </div>
              <div style="float: left;width: 44%;margin-left: 2%;">
                <el-button type="primary" style="width: 100%;background-color: rgba(255, 207, 5, 1);color: black;" @click="vCodeOpen('forgotPasswordForm')" :disabled="getVerifyCodeDisabled">{{verifyCodeText}}</el-button>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="stepTwo('forgotPasswordForm')" style="background-color: rgba(255, 207, 5, 1);color: black;width: 50%;margin-top: 8%;border-radius: 30px;">{{$t('forgotPassword.i_next')}}</el-button>
          </el-form-item>
        </el-form>
      </div>

      <div v-if="active == 1">
        <el-form ref="forgotPasswordForm" :model="forgotPasswordForm" :rules="forgotPasswordRules">
          <el-form-item :label="$t('forgotPassword.i_enterYourNewPass')" prop="pass">
            <el-input type="password" v-model="forgotPasswordForm.pass" show-password :placeholder="$t('forgotPassword.i_enterYourNewPass')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('register.i_checkYourPass')" prop="checkPass">
            <el-input type="password" v-model="forgotPasswordForm.checkPass" show-password :placeholder="$t('register.i_checkYourPass')"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="stepThree('forgotPasswordForm')" style="background-color: rgba(255, 207, 5, 1);color: black;width: 50%;margin-top: 8%;border-radius: 30px;">{{$t('forgotPassword.i_updatePass')}}</el-button>
          </el-form-item>
        </el-form>
      </div>

      <div v-if="active == 3">
        <img src="@/assets/common/success.png" style="width: 20%;margin-top: 6%;" />
        <div style="font-size: 18px;margin-bottom: 10%;margin-top: 10%;">{{$t('forgotPassword.i_successUpdatePass')}}<br />{{$t('forgotPassword.i_goLoginMsg')}}</div>
        <router-link to="/login">
          <el-button type="primary" style="background-color: rgba(255, 207, 5, 1);color: black;width: 50%;border-radius: 30px;">{{$t('login.i_goLogin')}}</el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Vcode from "vue-puzzle-vcode";
export default {
  components: {
    Vcode
  },
  data() {
    const validatorVerifyCode = (rule, value, callback) => {
      if (value.length != 6) {
        callback(new Error(this.$t("register.i_enter6VerifyCode")));
      } else {
        callback();
      }
    };
    const validatorPass = (rule, value, callback) => {
      const regex =
        /^[0-9]*$|^[a-zA-Z]*$|^[~!@#$%^&*]*$|^[A-Za-z0-9]*$|^[0-9~!@#$%^&*]*$|^[a-zA-Z~!@#$%^&*]*$|^(?=.*\d)(?=.*[a-zA-Z])(?=.*[~!@#$%^&*])[\da-zA-Z~!@#$%^&*]*$/;
      if (!regex.test(value)) {
        callback(new Error(this.$t("register.i_specialSymbols")));
      } else if (value.length < 6) {
        callback(new Error(this.$t("register.i_pass6")));
      } else if (value.length > 30) {
        callback(new Error(this.$t("register.i_pass30")));
      } else {
        callback();
      }
    };
    const validatorCheckPass = (rule, value, callback) => {
      if (value !== this.forgotPasswordForm.pass) {
        callback(new Error(this.$t("register.i_checkTwoPass")));
      } else {
        callback();
      }
    };
    return {
      isLoading: false,
      active: 0,
      // 验证码模态框是否出现
      isShow: false,
      verifyCodeText: this.$t("register.i_getVerifyCode"),
      timeCount: 60,
      timer: null,
      getVerifyCodeDisabled: false,
      forgotPasswordForm: {
        email: "",
        verifyCode: "",
        pass: "",
        checkPass: ""
      },
      forgotPasswordRules: {
        email: [
          {
            required: true,
            message: this.$t("register.i_emailAddressEmpty"),
            trigger: ["blur", "change"]
          },
          {
            type: "email",
            message: this.$t("register.i_checkEmail"),
            trigger: ["blur", "change"]
          }
        ],
        verifyCode: [
          {
            required: true,
            message: this.$t("register.i_verifyCodeEmpty"),
            trigger: ["blur", "change"]
          },
          {
            validator: validatorVerifyCode,
            trigger: ["blur", "change"]
          }
        ],
        pass: [
          {
            required: true,
            message: this.$t("register.i_passEmpty"),
            trigger: ["blur", "change"]
          },
          {
            validator: validatorPass,
            trigger: ["blur", "change"]
          }
        ],
        checkPass: [
          {
            required: true,
            message: this.$t("register.i_checkPassEmpty"),
            trigger: ["blur", "change"]
          },
          {
            validator: validatorPass,
            trigger: ["blur", "change"]
          },
          {
            validator: validatorCheckPass,
            trigger: ["blur", "change"]
          }
        ]
      }
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    stepTwo(formName) {
      this.$refs[formName].validateField(["email"], (validErr) => {
        if (!validErr) {
          this.$refs[formName].validateField(["verifyCode"], (validErr) => {
            if (!validErr) {
              this.isLoading = true;
              this.forgotPasswordForm.marker = true;
              this.$axios({
                url: "/webAndGame/webUser/user/forgotPassword",
                method: "post",
                params: this.forgotPasswordForm
              })
                .then((result) => {
                  this.isLoading = false;
                  if (result.data.code == 1) {
                    this.forgotPasswordForm.marker = false;
                    this.active = 1;
                  } else {
                    this.$message({
                      showClose: true,
                      message: result.data.message,
                      type: "error",
                      center: true
                    });
                  }
                })
                .catch((error) => {
                  this.isLoading = false;
                  return Promise.reject(error);
                });
            }
          });
        }
      });
    },
    stepThree(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.isLoading = true;
          this.$axios({
            url: "/webAndGame/webUser/user/forgotPassword",
            method: "post",
            params: this.forgotPasswordForm
          }).then((result) => {
            this.isLoading = false;
            if (result.data.code == 1) {
              this.active = 3;
            } else {
              this.$message({
                showClose: true,
                message: result.data.message,
                type: "error",
                center: true
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    vCodeOpen(formName) {
      this.$refs[formName].validateField(["email"], (validErr) => {
        if (!validErr) {
          this.isShow = true;
        }
      });
    },
    close() {
      this.isShow = false;
    },
    vCodeSuccess() {
      this.getVerifyCode("forgotPasswordForm");
      this.isShow = false;
    },
    getVerifyCode(formName) {
      this.$refs[formName].validateField(["email"], (validErr) => {
        if (!validErr) {
          if (!this.timer) {
            this.getVerifyCodeDisabled = true;
            this.verifyCodeText = this.$t("register.i_inProgress");
            this.$axios({
              url:
                "/webAndGame/webUser/user/getVerifyCode?email=" +
                this.forgotPasswordForm.email +
                "&type=2",
              method: "get"
            })
              .then((result) => {
                if (result.data.code == 1) {
                  this.getVerifyCodeDisabled = true;
                  this.verifyCodeText =
                    this.timeCount + "s " + this.$t("register.i_reRequest");
                  this.timer = setInterval(() => {
                    if (this.timeCount > 1) {
                      this.getVerifyCodeDisabled = true;
                      this.timeCount--;
                      this.verifyCodeText =
                        this.timeCount + "s " + this.$t("register.i_reRequest");
                    } else {
                      this.getVerifyCodeDisabled = false;
                      this.verifyCodeText = this.$t("register.i_getVerifyCode");
                      clearInterval(this.timer);
                      this.timer = null;
                      this.timeCount = 60;
                    }
                  }, 1000);
                  this.$message({
                    message: this.$t("register.i_verifyCodeMsg"),
                    type: "success",
                    center: true,
                    showClose: true
                  });
                } else {
                  this.getVerifyCodeDisabled = false;
                  this.verifyCodeText = this.$t("register.i_getVerifyCode");
                  this.$message({
                    showClose: true,
                    message: result.data.message,
                    type: "error",
                    center: true
                  });
                }
              })
              .catch((error) => {
                this.getVerifyCodeDisabled = false;
                this.verifyCodeText = this.$t("register.i_getVerifyCode");
                return Promise.reject(error);
              });
          }
        }
      });
    }
  }
};
</script>

<style>
#forgot-password-layout {
  width: 100%;
  height: 100%;
}
#forgot-password-layout .f-page-header {
  padding-top: 4%;
  padding-left: 4%;
  padding-right: 4%;
  margin-bottom: 8%;
}
#forgot-password-layout .forgot-password-form {
  margin-top: 5%;
  margin-left: 6%;
  margin-right: 6%;
}
</style>
