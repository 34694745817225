<template>
  <div id="invite-layout">
    <el-main style="height: auto;min-height: 60%;">
      <div style="padding-bottom: 36.3%;margin-bottom: 18%;">
        <div style="text-align: left;margin-bottom: 5%;">
          <img src="@/assets/images/invite000.png" style="width: 50%;" />
        </div>
        <img v-if="$t('invite.inviteImg')=='zh'" src="@/assets/images/invite001.png" style="width: 100%;" />
        <img v-if="$t('invite.inviteImg')=='en'" src="@/assets/images/invite001-1.png" style="width: 100%;" />
      </div>
    </el-main>
    <div class="invite-footer">
      <img src="@/assets/images/invite002.png" style="width: 100%;margin-bottom: -4px;" />
      <div style="background-color: rgba(50, 66, 31, 1);padding-top: 5%;padding-bottom: 12%;">
        <div class="invite-code">
          {{$t('inviteFriend.i_code')}}：
          <div class="invite-code-text">
            <span @click="copyVal($event.target.innerText)">{{inviteCode}}</span>
            <span @click="copyVal($event.currentTarget.previousElementSibling.innerText)" style="margin-left: 2%;">
              <img src="@/assets/common/copyIconW.png" style="width: 12px;margin-bottom: 10px;" />
            </span>
          </div>
        </div>

        <el-button type="success" @click="register($event)" style="border-radius: 30px;background-color: #F4F222;">
          <img src="@/assets/images/login001.png" style="width: 30px;border-radius: 6px;float: left;" />
          <span style="font-size: 18px;margin-left: 10px;line-height: 30px;color: black;">
            Pocket Cash
            <el-icon class="el-icon-right" style="margin-left: 8px;"></el-icon>
          </span>
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      inviteCode: ""
    };
  },
  methods: {
    copyVal(val) {
      // 创建一个 Input标签
      let oInput = document.createElement("input");
      oInput.value = val;
      document.body.appendChild(oInput);
      // 选择对象
      oInput.select();
      // 执行浏览器复制命令
      // 复制命令会将当前选中的内容复制到剪切板中
      // 如这里构建的 Input标签
      document.execCommand("Copy");
      this.$message({
        message: this.$t("common.i_copy"),
        type: "success",
        showClose: true,
        center: true
      });
      // 复制成功后再将构造的标签 移除
      oInput.remove();
    },
    register(e) {
      if (localStorage.getItem("token") != null) {
        this.$router.push("/index");
      } else {
        this.$router.push(
          "/register?inviteCode=" +
            e.currentTarget.parentElement.firstElementChild.firstElementChild
              .firstElementChild.innerText
        );
      }
    }
  },
  created() {
    this.inviteCode = this.$route.query.referrer;
  }
};
</script>

<style>
#invite-layout {
  background: linear-gradient(
      180deg,
      rgba(255,243,174, 0.5) 0%,
      rgba(255,243,174, 0.7) 0.12%,
      rgba(255, 233, 193, 1) 99.37%,
      rgba(255, 233, 193, 1) 100%
    )
    no-repeat;
  width: 100%;
  height: 100%;
}
.el-main {
  background: url("@/assets/images/loginBackground.png") no-repeat;
  background-size: 100%;
}
.el-image {
  width: 80%;
}
.invite-code {
  margin-bottom: 8%;
  font-size: 20px;
  color: white;
}
.invite-code-text {
  display: inline;
}
.invite-footer {
  position: relative;
  clear: both;
  margin-top: -36.3%;
  height: 0;
}
</style>
